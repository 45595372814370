<template>
  <div class="phoneBox">
    <img src="@/assets/images/chatTop.png" alt="" />
    <div class="phoneTitle">彩蛋生活馆</div>
    <div class="allContent" v-if="type == 'welcome'">
      <div class="itemBox" style="align-self: flex-end">
        <div class="contentBox rightContent">你好</div>
        <img src="@/assets/images/userHeader.png" alt="" />
      </div>
      <div class="itemBox" v-show="obj.sendTxtMsg.showflag || obj.sendCommonProblem.showflag">
        <img src="@/assets/images/shopHeader.png" alt="" />
        <div class="contentBox leftContent">
          <div v-show="obj.sendTxtMsg.showflag" class="welcome">
            {{ obj.sendTxtMsg.content }}
          </div>
          <div class="manyProblem" v-show="obj.sendCommonProblem.showflag">
            <template v-for="(item, index) in obj.sendCommonProblem.list" :key="index">
              <div>· [回复{{ item.keyword }}]{{ item.asking }}</div>
            </template>
          </div>
        </div>
      </div>
      <div class="itemBox" v-show="obj.sendGoods.showflag">
        <img src="@/assets/images/shopHeader.png" alt="" />
        <div class="contentBox leftContent">
          <div>为您推荐</div>
          <template v-for="(item, index) in goodsList" :key="index">
            <div class="goodsBox">
              <img :src="item.img" alt="" />
              <div class="desc">
                <div>{{ item.name }}</div>
                <div>￥{{ item.price.toFixed(2) }}</div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="itemBox" v-show="obj.wxCode.showflag">
        <img src="@/assets/images/shopHeader.png" alt="" />
        <div class="contentBox leftContent">
          <img v-if="cardUrl" :src="cardUrl" alt="" />
        </div>
      </div>
    </div>
    <div class="allContent" v-if="type == 'waiting'">
      <div class="itemBox" style="align-self: flex-end">
        <div class="contentBox rightContent">你好</div>
        <img src="@/assets/images/userHeader.png" alt="" />
      </div>
      <div class="itemBox" v-show="obj.sendTxtMsg.showflag || obj.sendCommonProblem.showflag">
        <img src="@/assets/images/shopHeader.png" alt="" />
        <div class="contentBox leftContent">
          <div v-show="obj.sendTxtMsg.showflag" class="welcome">
            {{ obj.sendTxtMsg.content }}
          </div>
          <div class="manyProblem" v-show="obj.sendCommonProblem.showflag">
            <template v-for="(item, index) in obj.sendCommonProblem.list" :key="index">
              <div>· [回复{{ item.keyword }}]{{ item.asking }}</div>
            </template>
          </div>
        </div>
      </div>
      <div class="itemBox" style="align-self: flex-end" v-show="obj.waitingTips.showflag">
        <div class="contentBox rightContent">能走哪些快递啊</div>
        <img src="@/assets/images/userHeader.png" alt="" />
      </div>
      <div class="itemBox" v-show="obj.waitingTips.showflag">
        <img src="@/assets/images/shopHeader.png" alt="" />
        <div class="contentBox leftContent">您前面还有3位，请等待</div>
      </div>
      <div class="customTips" v-show="obj.manCustom.showflag">久等了，人工在线客服为您服务</div>
    </div>
    <div class="allContent" v-if="type == 'offwork'">
      <div class="itemBox" style="align-self: flex-end">
        <div class="contentBox rightContent">你好</div>
        <img src="@/assets/images/userHeader.png" alt="" />
      </div>
      <div class="itemBox" v-show="obj.sendTxtMsg.showflag || obj.sendCommonProblem.showflag">
        <img src="@/assets/images/shopHeader.png" alt="" />
        <div class="contentBox leftContent">
          <div v-show="obj.sendTxtMsg.showflag" class="welcome">
            {{ obj.sendTxtMsg.content }}
          </div>
          <div class="manyProblem" v-show="obj.sendCommonProblem.showflag">
            <template v-for="(item, index) in obj.sendCommonProblem.list" :key="index">
              <div>· [回复{{ item.keyword }}]{{ item.asking }}</div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="allContent" v-if="type == 'faq'">
      <div class="itemBox" style="align-self: flex-end">
        <div class="contentBox rightContent">你好</div>
        <img src="@/assets/images/userHeader.png" alt="" />
      </div>
      <div class="itemBox">
        <img src="@/assets/images/shopHeader.png" alt="" />
        <div class="contentBox leftContent">
          <div class="welcome">这里展示首句欢迎语、排队中提醒 或下班后提醒</div>
          <div class="manyProblem">
            <template v-for="(item, index) in obj" :key="index">
              <div>· [回复{{ item.keyword }}]{{ item.asking }}</div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="allContent" v-if="type == 'selfMenu'">
      <div v-if="obj.type === 'order'">
        <img src="@/assets/images/orderMenuPhone.png" alt="" />
      </div>
      <div v-if="obj.type === 'likes'">
        <img src="@/assets/images/likesMenuPhone.png" alt="" />
      </div>
      <div v-if="obj.type === 'coupon'" class="couponBox">
        <p>优惠券</p>
        <div class="coupon">
          <i class="iconfont icon-youhuiquan"></i>
          <span>请添加优惠券</span>
        </div>
      </div>
      <div class="itemBox" v-if="obj.type === 'question'">
        <img src="@/assets/images/shopHeader.png" alt="" />
        <div class="contentBox leftContent">
          <div class="manyProblem">
            <template v-for="(item, index) in commonProblem" :key="index">
              <div>{{ item.asking }}</div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="menuBox">
      <template v-for="(item, index) in selfMenuList" :key="index">
        <div>{{ item.menu_name }}</div>
      </template>
    </div>
    <img src="@/assets/images/phoneFooter.png" alt="" />
  </div>
</template>

<script>
import { ref } from 'vue'
export default {
  props: {
    type: {
      type: String,
      default: () => ''
    },
    obj: {
      type: Object,
      default: () => {}
    },
    cardUrl: {
      type: String,
      default: ''
    },
    selfMenuList: {
      type: Array,
      default: () => []
    },
    commonProblem: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const goodsList = ref([])
    goodsList.value = [
      {
        img: require('@/assets/images/good1.png'),
        name: '西装套装夏季新款宽松休闲满印潮',
        price: 228.0
      },
      {
        img: require('@/assets/images/good2.png'),
        name: '无袖T恤夏新款宽松背心oversize潮',
        price: 158.0
      },
      {
        img: require('@/assets/images/good3.png'),
        name: '短袖T恤夏季新款宽松',
        price: 158.0
      }
    ]
    return {
      goodsList
    }
  }
}
</script>

<style lang="less" scoped>
.phoneBox {
  width: 375px;
  position: relative;
  img {
    vertical-align: bottom;
  }
  .phoneTitle {
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: bold;
    font-size: 16px;
  }
}
.allContent {
  min-height: 440px;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  .customTips {
    color: #666;
    font-size: 12px;
    text-align: center;
    margin-bottom: 18px;
  }
  .itemBox {
    display: flex;
    margin-bottom: 18px;
    & > img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    .contentBox {
      padding: 10px;
      border-radius: 4px;
      line-height: 1.5;
      max-width: 208px;

      .welcome {
        white-space: pre-wrap;
      }
      .goodsBox {
        display: flex;
        margin-top: 12px;
        img {
          width: 56px;
          height: 56px;
          margin-right: 8px;
        }
        .desc {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          & > div:first-child {
            line-height: 1.3;
          }
          & > div:last-child {
            line-height: 1;
            color: red;
          }
        }
      }
    }
  }
  .rightContent {
    background-image: linear-gradient(to right bottom, #fc314d, #f65240);
    color: #fff;
    margin-right: 10px;
  }
  .leftContent {
    background-color: #fff;
    margin-left: 10px;
  }
}
.menuBox {
  background-color: #f7f7f7;
  border-top: 1px solid #ccc;
  display: flex;
  padding-top: 5px;
  & > div {
    margin-left: 8px;
    border-radius: 8px;
    font-size: 12px;
    color: #666;
    background-color: #f7f7f7;
    border: 1px solid #bbb;
    padding: 0 5px;
    white-space: nowrap;
  }
}
.couponBox {
  width: 100%;
  background-color: #ffff;
  padding: 0 6px 6px 6px;
  p {
    font-size: 14px;
    line-height: 1;
    margin: 10px 0;
    padding: 0 12px;
    color: #000;
  }
  .coupon {
    height: 80px;
    background-color: #f7f8fa;
    color: #bcbdbf;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 22px;
      margin-right: 6px;
    }
  }
}
</style>
